.banner {
  padding-top: 15px;
  padding-bottom: 20px;
  display: flex;
  border-bottom: 1px solid #B1B1B140;
  margin-bottom: 8px;
}

.title {
  font-weight: 300;
  font-size: 30px;
  line-height: 36px;
  width: 100%;
  max-width: 500px;
  min-width: 100px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.actionWrapper {
  display: flex;
  width: 100%;
  position: relative;
}

.floatRight {
  display: flex;
  position: absolute;
  right: 0;
}